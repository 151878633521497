import { storeToRefs } from 'pinia'
import { Dark, date, Lang, copyToClipboard, Notify } from 'quasar'
import { i18n } from 'boot/i18n';
import { useUserStore } from 'stores/user'
import { setLocale } from '@vee-validate/i18n';
import { setCssVar } from 'quasar'
import * as cl from "@/assets/data/country-list.json";

export function useAppUtils() {
  const modules = import.meta.glob('../../node_modules/quasar/lang/(de|en-US).js')
  const userStore = useUserStore();
  const countryList = cl.default;
  const { toggleStoreTheme, setPrimaryColor, setUserLanguage, setUserTimezone, setUserDateFormat, setUserTimeFormat, toggleGrid: toggleStoreGrid } = userStore
  const { userSettings, defaultDateTimeFormat } = storeToRefs(userStore)

  const setQuasarLanguage = (lang) => {
    try {
      modules[`../../node_modules/quasar/lang/${lang}.js`]().then(ln => {
        Lang.set(ln.default)
      })
    }
    catch (error) {
      throw error
    }
  }
  const setActiveTheme = () => {
    Dark.set(userSettings.value.isDarkTheme)
  }
  const applyPrimaryColor = () => {
    setCssVar('primary', userSettings.value.primaryColor)
  }
  const toggleTheme = () => {
    Dark.toggle()
    toggleStoreTheme()
  }
  const toggleGrid = () => {
    toggleStoreGrid()
  }
  const setActiveLanguage = () => {
    i18n.locale.value = userSettings.value.language;
    setQuasarLanguage(userSettings.value.language);
    setLocale(userSettings.value.language);
  }
  const changeUserLanguage = (lang) => {
    setUserLanguage(lang)
    setQuasarLanguage(lang);
    i18n.locale.value = lang;
    setLocale(lang);
  }
  const changeUserTimezone = (tz) => {
    setUserTimezone(tz)
  }
  const changeUserDateFormat = (df) => {
    setUserDateFormat(df)
  }
  const changeUserTimeFormat = (tf) => {
    setUserTimeFormat(tf)
  }

  const changePrimaryColor = (color) => {
    setPrimaryColor(color)
    setCssVar('primary', color)
  }
  const formatDateTime = (dateTimeString, format = null) => {
    if (format) {
      return date.formatDate(dateString, format)
    }
    return date.formatDate(dateTimeString, defaultDateTimeFormat.value)
  }
  const formatDate = (dateString, format = null) => {
    if (format) {
      return date.formatDate(dateString, format)
    }
    return date.formatDate(dateString, userSettings.value.dateFormat)
  }
  const formatTime = (timeString, format = null) => {
    if (format) {
      return date.formatDate(timeString, format)
    }
    return date.formatDate(timeString, userSettings.value.timeFormat)
  }

  const copyTextToClipboard = (text) => {
    copyToClipboard(text).then(() => {
      Notify.create({
        message: i18n.t('app.copied-to-clipboard'),
        color: 'positive',
      })
    })
  }


  return {
    setActiveTheme,
    defaultDateTimeFormat,
    toggleTheme,
    changePrimaryColor,
    userSettings,
    applyPrimaryColor,
    toggleGrid,
    formatDateTime,
    formatDate,
    formatTime,
    countryList,
    copyTextToClipboard,
    setActiveLanguage,
    changeUserLanguage,
    changeUserTimezone,
    changeUserDateFormat,
    changeUserTimeFormat
  };
}
