

import authMiddleware from '@/middlewares/authMiddleware'
import organizationMiddleware from '@/middlewares/organizationMiddleware'
import Settings from '@/router/settings'
import Auth from '@/router/auth'
import User from '@/router/user'
import Contact from '@/router/contact'
import Communication from '@/router/communication'
const routes = [
  {
    path: "/no-organization",
    name: "no-organization",
    component: () => import('pages/status/NoOrganizationPage.vue'),
  },
  {
    path: "/access-denied",
    name: "no-permisstion",
    component: () => import('pages/status/NoPermissionsPage.vue'),
  },
  {
    path: "/error-500",
    name: "500",
    component: () => import('pages/status/Error500Page.vue'),
  },
  {
    path: "/error-503",
    name: "503",
    component: () => import('pages/status/Error503Page.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('layouts/AuthLayout.vue'),
    redirect: { name: "login" },
    children: [
      ...Auth
    ]
  },
  {
    path: '',
    component: () => import('layouts/MainLayout.vue'),
    redirect: { name: "home" },
    children: [
      {
        meta: {
          middleware: [authMiddleware, organizationMiddleware]
        },
        path: "/:organization?/home",
        name: "home",
        component: () => import('pages/HomePage.vue')
      },
      ...Settings,
      ...User,
      ...Contact,
      ...Communication
    ]
  },

  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('pages/status/Error404Page.vue'),

  },
]

export default routes
