import authMiddleware from '@/middlewares/authMiddleware'
import organizationMiddleware from '@/middlewares/organizationMiddleware'
export default [
  {
    path: ":organization?/communication",
    name: "communication",
    component: () => import('pages/communication/CommunicationPage.vue'),
    title: "Communication",
    redirect: { name: "list-communication" },
    children: [
      {
        path: "list",
        name: "list-communication",
        meta: {
          middleware: [authMiddleware, organizationMiddleware],
          icon: "mdi-forum-outline",
          titleTranslationKey: "communication.communication-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'communication.communication-list-title',
              icon: 'mdi-forum-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/communication/ListCommunicationComponent.vue')
      },
      {
        path: "create",
        name: "create-communication",
        meta: {
          middleware: [authMiddleware, organizationMiddleware],
          titleTranslationKey: "communication.create-communication-title",
          icon: "mdi-message-plus-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'communication.communication-list',
              icon: 'mdi-forum-outline',
              disabled: false,
              exact: true,
              routeName: 'list-communication',
            },
            {
              translationKey: 'communication.create-communication',
              icon: 'mdi-forum-plus-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/communication/CreateCommunicationComponent.vue')
      },
      {
        path: "show/:id",
        name: "show-communication",
        meta: {
          middleware: [authMiddleware, organizationMiddleware],
          titleTranslationKey: "communication.show-communication-title",
          icon: "mdi-chat-processing-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'communication.communication-list',
              icon: 'mdi-forum-outline',
              disabled: false,
              exact: true,
              routeName: 'list-communication',
            },
            {
              translationKey: 'communication.show-communication',
              icon: 'mdi-chat-processing-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/communication/ShowCommunicationComponent.vue'),
      },
      {
        path: "settings",
        name: "communication-settings",
        title: "Communication settings",
        redirect: { name: "list-communication-settings" },
        children: [
          {
            path: "list",
            name: "list-communication-settings",
            meta: {
              middleware: [authMiddleware, organizationMiddleware],
              icon: "mdi-message-cog-outline",
              titleTranslationKey: "communication.communication-settings-list-title",
              breadcrumbs: [
                {
                  translationKey: 'nav-bar.home-title',
                  icon: 'mdi-home',
                  disabled: false,
                  exact: true,
                  routeName: 'home',
                },
                {
                  translationKey: 'communication.communication-settings-list',
                  icon: 'mdi-message-cog-outline',
                  disable: true,
                },
              ],
            },
            component: () => import('@/components/communication/settings/ListCommunicationSettingsComponent.vue')
          },
          {
            path: "show/:name",
            name: "show-communication-settings",
            meta: {
              middleware: [authMiddleware, organizationMiddleware],
              titleTranslationKey: "communication.show-communication-settings-title",
              icon: "mdi-message-settings-outline",
              breadcrumbs: [
                {
                  translationKey: 'nav-bar.home-title',
                  icon: 'mdi-home',
                  disabled: false,
                  exact: true,
                  routeName: 'home',
                },
                {
                  translationKey: 'communication.communication-settings-list',
                  icon: 'mdi-message-cog-outline',
                  disabled: false,
                  exact: true,
                  routeName: 'list-communication-settings',
                },
                {
                  translationKey: 'communication.show-communication-settings',
                  icon: 'mdi-message-settings-outline',
                  disable: true,
                },
              ],
            },
            component: () => import('@/components/communication/settings/ShowCommunicationSettingsComponent.vue'),
          },
        ]
      },
      {
        path: "chat/:id?",
        name: "communication-chat",
        meta: {
          middleware: [authMiddleware, organizationMiddleware],
          icon: "mdi-chat-processing",
          padding: false,
          titleTranslationKey: "communication.communication-chat-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'communication.communication-chat-list',
              icon: 'mdi-chat-processing',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/communication/chat/ChatWrapper.vue'),
      },
    ]
  }
];
